// - - - - - - - - - - - - - - - - - -

// Listing posts

// - - - - - - - - - - - - - - - - - -


.listing {
	margin-top: -40px;

	@include mq(tabletp) {
		margin-top: -60px;
	}
}

.post {
	position: relative;
	float: left;
	clear: both;
	width: 100%;
	margin-top: 30px;
	cursor: pointer;

	&:first-child {
		margin-top: 0;
	}

	@include mq(tabletl) {
		margin-top: 60px;
		background: $background-alt-color;
	}

	&:hover {
		
		.post__image {
			@include scale(1.2);
		}

		.post__content {
			@include box-shadow(0, 6px, 16px, rgba(0,0,0,0.1));
		}

		.post__title a {
			color: $accent-color;
		}
	}

	&:nth-child(2n) {

		@include mq(tabletl) {

			.post__image-wrap {
				left: auto;
				right: 0;
			}

			.post__content-wrap {
				float: left;
				padding: 30px 0 30px 30px;
			}

			.post__content {
				margin: 0 -30px 0 0;
			}
		}
	}
}

.post__image-wrap {
	position: relative;
	height: 0;
	margin: 0;
	padding-bottom: 56.25%;
	overflow: hidden;

	@include mq(tabletl) {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50%;
		height: auto;
		padding-bottom: 0;
	}
}

.post__image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include transition(all 1s cubic-bezier(0.2, 0.3, 0, 1));
}

.post__content-wrap {
	float: left;
	width: 100%;
	position: relative;
	padding: 0 20px 20px;
	background: $background-alt-color;

	@include mq(tabletp) {
		padding: 0 30px 30px;
	}
	
	@include mq(tabletl) {
		float: right;
		padding: 30px 30px 30px 0;
		width: 50%;
	}
}

.post__content {
	position: relative;
	margin-top: -20px;
	padding: 20px;
	background: $background-color;
	@include box-shadow(0, 6px, 16px, rgba(0,0,0,0.05));
	@include transition(all 0.4s cubic-bezier(0.2, 0.3, 0, 1));

	@include mq(tabletp) {
		margin-top: -30px;
		padding: 30px;
	}
	
	@include mq(tabletl) {
		margin: 0 0 0 -30px;
		min-height: 400px;
	}
}

.post__title {
	
	a {
		font-weight: $h2-weight;
	}
}

.post__subtitle {
	margin-top: 5px;
	font-size: 15px;
	color: $accent-color;
}

.post__description {

}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Pagination

.pagination {
	margin-top: 30px;

	@include mq(tabletl) {
		margin-top: 60px;
	}
}

.pagination__prev,
.pagination__next {
	width: 50%;
	padding: 20px;
	border: 0;

	@include mq(tabletp) {
		padding: 30px;
		font-size: $p-small;
	}

	@include mq(laptop) {
		font-size: $p-medium;
	}

	&:hover {
		background: $accent-color;
	}
}

.pagination__prev {
	float: left;
	background: $text-medium-color;
}

.pagination__next {
	float: right;
	text-align: right;
	background: $text-dark-color;
}